// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, DocumentData, CollectionReference } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import Playlist from '../models/playlist'
import { ISong } from '../models/song'
import { ISlideshow } from '../models/slideshow'

const firebaseConfig = {
  apiKey: 'AIzaSyAKdv2V_5qgoaiP_owM7k17p7jgwjP11rk',
  authDomain: 'efatasongbook.firebaseapp.com',
  projectId: 'efatasongbook',
  storageBucket: 'efatasongbook.appspot.com',
  messagingSenderId: '201015372285',
  appId: '1:201015372285:web:f6c9bd8bc383ce0418d194'
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const projectAuth = getAuth()

const dbCollections = {
  playlists: 'playlists',
  songs: 'songs',
  slideshows: 'slideshows'
}

const createCollection = <T = DocumentData,>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>
}

const playlistCollection = createCollection<Playlist>(dbCollections.playlists)
const songsCollection = createCollection<ISong>(dbCollections.songs)
const slideshowCollection = createCollection<ISlideshow>(dbCollections.slideshows)

export { projectAuth, playlistCollection, songsCollection, slideshowCollection }
