import { FC, useEffect, useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import SegmentIcon from '@mui/icons-material/Segment'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Collapse, Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'
import SaveIcon from '@mui/icons-material/Save'
import Tooltip from '@mui/material/Tooltip'
import PlusOneIcon from '@mui/icons-material/PlusOne'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { useSongTransposeContext } from '../../context/SongTransposeContext'
import { useSongContext } from '../../context/SongContext'
import { songIdToString } from '../../helpers/utils'
import { IPlaylist, ISongInPlaylistFirestore, PlaylistArtist } from '../../models/playlist'
import { useAuthContext } from '../../hooks/useAuthContext'
import ManagePlaylistsDialog from '../dialogs/ManagePlaylistsDialog'
import { ReactComponent as Guitar } from '../../assets/svgs/guitar.svg'
import { ISong } from '../../models/song'
import PlaylistArtistsDialog from './toolbar/PlaylistArtistsDialog'
import SongArtistAvatar from './misc/SongArtistAvatar'

const capoOptions = [
  [-6, -5, -4, -3, -2, -1],
  [0, 1, 2, 3, 4, 5],
  [6, 7, 8, 9, 10, 11]
]

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))

interface Props {
  song: ISong
  showAddToPlaylist: boolean
  onEditClick: () => void
  onDeleteClick: () => void
  songInPlaylist?: ISongInPlaylistFirestore
  onSongInPlaylistSave?: (songInPlaylist: Omit<ISongInPlaylistFirestore, 'id'>) => void
  playlist?: IPlaylist
  artist?: PlaylistArtist
}

const chordOption = 'chords'
const captionOption = 'captions'

export const SongToolbar: FC<Props> = (props) => {
  const {
    toggleChordsVisibility,
    toggleDirectivesVisibility,
    isChordsVisible,
    isDirectivesVisible
  } = useSongContext()
  const { transpose, transposeHowMuch, setCapo, capo } = useSongTransposeContext()

  const {
    state: { user }
  } = useAuthContext()

  const [isCapoOptionsOpen, setIsCapoOptionsOpen] = useState(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [isAddToPlaylistDialogOpen, setIsAddToPlaylistDialogOpen] = useState(false)
  const [isArtistsOpen, setIsArtistsOpen] = useState(false)
  const [isAddNewArtistDialogOpen, setIsAddNewArtistDialogOpen] = useState(false)

  const capoOptionsAnchorRef = useRef<HTMLButtonElement>(null)
  const optionsAnchorRef = useRef<HTMLButtonElement>(null)
  const artistsAnchorRef = useRef<HTMLButtonElement>(null)

  const songId = songIdToString(props.song.id)

  useEffect(() => {
    if (props.songInPlaylist?.transpose !== undefined) {
      transpose(props.songInPlaylist.transpose, true)
    }
  }, [props.songInPlaylist?.transpose, songId, transpose])

  useEffect(() => {
    if (props.songInPlaylist?.capo !== undefined) {
      setCapo(props.songInPlaylist.capo)
    }
  }, [props.songInPlaylist?.capo, songId, setCapo])

  const isInPlaylist = props.songInPlaylist !== undefined

  const handleToggle = () => {
    setIsOptionsOpen((prevOpen) => !prevOpen)
  }

  const onCapoChange = (newCapo: number) => {
    setCapo(newCapo)
    setIsCapoOptionsOpen(false)
  }

  const handleCapoOptionsClose = (event: Event) => {
    if (
      capoOptionsAnchorRef.current &&
      capoOptionsAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setIsCapoOptionsOpen(false)
  }

  const handleOptionsClose = (event: Event) => {
    if (
      optionsAnchorRef.current &&
      optionsAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setIsOptionsOpen(false)
  }

  const buttonsToggledOn: string[] = ['save-transpose']
  if (isChordsVisible) {
    buttonsToggledOn.push(chordOption)
  }
  if (isDirectivesVisible) {
    buttonsToggledOn.push(captionOption)
  }

  const saveTranspose = () => {
    if (props.onSongInPlaylistSave && props.songInPlaylist) {
      props.onSongInPlaylistSave({
        transpose: transposeHowMuch,
        capo,
        artist: props.artist ?? null
      })
    }
  }

  const onSongArtistChange = (newArtist: PlaylistArtist) => {
    if (props.onSongInPlaylistSave && props.songInPlaylist) {
      props.onSongInPlaylistSave({
        ...props.songInPlaylist,
        artist: newArtist
      })
    }

    setIsArtistsOpen(false)
  }

  const showSaveButton =
    isInPlaylist &&
    (props.songInPlaylist?.transpose !== transposeHowMuch || props.songInPlaylist?.capo !== capo)

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap'
        }}
      >
        <StyledToggleButtonGroup value={buttonsToggledOn}>
          <ToggleButton
            value={chordOption}
            onClick={toggleChordsVisibility}
            sx={{ px: 1.6 }}
          >
            <Guitar style={{ width: 19, height: 19 }} />
          </ToggleButton>
          <ToggleButton
            value={captionOption}
            onClick={toggleDirectivesVisibility}
          >
            <SegmentIcon />
          </ToggleButton>

          <Collapse
            in={isChordsVisible}
            orientation={'horizontal'}
          >
            <ToggleButton
              value={'transpose-up'}
              onClick={() => transpose(1)}
            >
              <ExpandLessIcon />
            </ToggleButton>
          </Collapse>

          <Collapse
            in={isChordsVisible}
            orientation={'horizontal'}
          >
            <ToggleButton
              value={'transpose-down'}
              onClick={() => transpose(-1)}
            >
              <ExpandMoreIcon />
            </ToggleButton>
          </Collapse>

          <Collapse
            in={isInPlaylist && isChordsVisible}
            orientation={'horizontal'}
          >
            <ToggleButton
              value={'capo'}
              onClick={() => setIsCapoOptionsOpen((_) => !_)}
              ref={capoOptionsAnchorRef}
            >
              <Tooltip title={'Kapo / Dodatočná úprava tóniny na nástroji'}>
                <PlusOneIcon />
              </Tooltip>
            </ToggleButton>
          </Collapse>

          <Collapse
            in={user && showSaveButton}
            orientation={'horizontal'}
          >
            <ToggleButton
              value={'save-transpose'}
              onClick={saveTranspose}
              color={'primary'}
            >
              <Tooltip title={'Uložiť tóninu piesne v playliste'}>
                <SaveIcon />
              </Tooltip>
            </ToggleButton>
          </Collapse>

          {user && props.showAddToPlaylist && (
            <ToggleButton
              value={'add-to-playlist'}
              onClick={() => setIsAddToPlaylistDialogOpen(true)}
            >
              <PlaylistAddIcon />
            </ToggleButton>
          )}

          {user && props.song.id.source === 'own' && (
            <ToggleButton
              value='more'
              onClick={handleToggle}
              ref={optionsAnchorRef}
            >
              <MoreVertIcon />
            </ToggleButton>
          )}

          {user && props.playlist && (
            <ToggleButton
              value='artist'
              onClick={() => setIsArtistsOpen((_) => !_)}
              ref={artistsAnchorRef}
            >
              <Tooltip title={'Kto vedie pieseň'}>
                {props.artist ? (
                  <SongArtistAvatar
                    artist={props.artist}
                    size={'small'}
                  />
                ) : (
                  <PersonRoundedIcon />
                )}
              </Tooltip>
            </ToggleButton>
          )}
        </StyledToggleButtonGroup>

        <Popper
          sx={{
            zIndex: 1
          }}
          open={isCapoOptionsOpen}
          anchorEl={capoOptionsAnchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCapoOptionsClose}>
                  <Grid
                    container
                    spacing={1}
                  >
                    {capoOptions.map((_, index) => (
                      <Grid
                        key={index}
                        item
                        xs={4}
                      >
                        <MenuList autoFocusItem>
                          {_.map((__) => (
                            <MenuItem onClick={() => onCapoChange(__)}>{__}</MenuItem>
                          ))}
                        </MenuList>
                      </Grid>
                    ))}
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Popper
          sx={{
            zIndex: 1
          }}
          open={isOptionsOpen}
          anchorEl={optionsAnchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleOptionsClose}>
                  <MenuList
                    id='split-button-menu'
                    autoFocusItem
                  >
                    <MenuItem onClick={props.onEditClick}>Upraviť</MenuItem>
                    <MenuItem onClick={props.onDeleteClick}>Odstrániť</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Popper
          sx={{
            zIndex: 1
          }}
          open={isArtistsOpen}
          anchorEl={artistsAnchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setIsArtistsOpen(false)}>
                  <MenuList autoFocusItem>
                    {(props.playlist?.artists ?? []).map((_) => (
                      <MenuItem
                        key={_.name}
                        onClick={() => onSongArtistChange(_)}
                      >
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          gap={1}
                        >
                          <SongArtistAvatar
                            artist={_}
                            size={'small'}
                          />
                          {_.name}
                        </Stack>
                      </MenuItem>
                    ))}
                    <MenuItem onClick={() => setIsAddNewArtistDialogOpen(true)}>
                      Pridať nového umelca
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Paper>

      {user && (
        <>
          <ManagePlaylistsDialog
            open={isAddToPlaylistDialogOpen}
            onClose={() => setIsAddToPlaylistDialogOpen(false)}
            song={props.song}
            transpose={transposeHowMuch}
          />
          {props.playlist && (
            <PlaylistArtistsDialog
              open={isAddNewArtistDialogOpen}
              onClose={() => setIsAddNewArtistDialogOpen(false)}
              playlist={props.playlist}
            />
          )}
        </>
      )}
    </>
  )
}
