import { ISong, Song4334 } from '../models/song'
import { ISongInPlaylist, ISongInPlaylistFirestore, SongId } from '../models/playlist'
import { getStandardChordKey, transposeChord } from './chords'

export const song4334ToSong = (song4334: Song4334): ISong => ({
  id: { id: song4334.id.toString(), source: '4334' },
  title: song4334.title.rendered,
  album: song4334.album?.toString(),
  chordKey: transposeChord(
    getStandardChordKey(getKeyFromSong4334(song4334)),
    0 - romanToDecimal(song4334.capo)
  ),
  author: '', // TODO
  chordProText: song4334.chordpro
})

export const getKeyFromSong4334 = (song: Song4334): string => {
  const result = song.acpt.meta[0].meta_fields?.find((_) => _.name === 'key')?.value
  return result ? result : ''
}

export const orderSongsByPlaylist = (
  songs: ISongInPlaylist[],
  playlistIdsOrder: SongId[]
): ISongInPlaylist[] =>
  playlistIdsOrder
    .map((_) => songs.find((__) => __.id.id === _.id && __.id.source === _.source))
    .filter((_) => _ !== undefined) as ISongInPlaylist[]

export const songIdToString = (songId: SongId): string => `${songId.id}|${songId.source}`

export const removeMultipleFollowingEmptyStringsFromArray = (array: string[]): string[] => {
  const result = []
  let lastElement = ''
  for (const element of array) {
    if (element !== '' || lastElement !== '') {
      result.push(element)
    }
    lastElement = element
  }
  return result
}

export const addEmptyStringIfMultipleFollowingNonEmptyStrings = (
  array: string[],
  threshold: number
): string[] => {
  const result = []
  let count = 0

  for (const element of array) {
    if (element !== '') {
      count += 1
    } else {
      count = 0
    }

    result.push(element)

    if (count >= threshold) {
      result.push('')
      count = 0
    }
  }

  return result
}

export const splitByMultiple = (text: string, separators: string[]): string[] => {
  const result = [text]
  for (const separator of separators) {
    const newResult = []
    for (const element of result) {
      newResult.push(...element.split(separator))
    }
    result.splice(0, result.length, ...newResult)
  }
  return result
}

export const removeChordProSpecifics = (text: string): string => {
  return text
    .replace(/\[.*?]/g, '')
    .replace(/\{.*?}/g, '')
    .replace('&nbsp;', '')
}

export const removeChordProTag = (text: string): string => {
  return text.replace(/\[\/?chordpro]/g, '')
}

export const createSlidesFromText = (text: string): { text: string[] }[] => {
  const arr = removeMultipleFollowingEmptyStringsFromArray(
    addEmptyStringIfMultipleFollowingNonEmptyStrings(
      splitByMultiple(removeChordProSpecifics(text), ['\r\n', '\n']),
      4
    )
  )

  const result = []
  let currentSlide: { text: string[] } = { text: [] }
  for (const element of arr) {
    if (element === '') {
      result.push(currentSlide)
      currentSlide = { text: [] }
    } else {
      currentSlide.text.push(element)
    }
  }

  if (currentSlide.text.length > 0) {
    result.push(currentSlide)
  }

  return result
}

export const playlistSongsToMapById = (
  songs: ISongInPlaylistFirestore[]
): Map<string, ISongInPlaylistFirestore> => {
  const result = new Map<string, ISongInPlaylistFirestore>()
  for (const song of songs) {
    result.set(song.id.id, song)
  }
  return result
}

export const getYoutubeVideoId = (url: string): string | undefined => {
  const regex = /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|live\/)?([^\s&]+)/
  const matches = url.match(regex)

  if (matches && matches[5]) {
    return matches[5]
  }
  return undefined
}

export const delay = (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}

export const romanToDecimal = (romanNumeral: string | undefined): number => {
  if (!romanNumeral) {
    return 0
  }

  const romanValues: { [key: string]: number } = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000
  }

  let decimalValue = 0
  let prevValue = 0

  for (let i = romanNumeral.length - 1; i >= 0; i -= 1) {
    const currentSymbol = romanNumeral[i]
    const currentValue = romanValues[currentSymbol]

    if (currentValue < prevValue) {
      decimalValue -= currentValue
    } else {
      decimalValue += currentValue
    }

    prevValue = currentValue
  }

  return decimalValue
}

export const chunkArray = <T>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
    arr.slice(index * size, index * size + size)
  )

export const getFirestoreSongInPlaylistFromFullSongInPlaylist = (
  songInPlaylist: ISongInPlaylist
): ISongInPlaylistFirestore => ({
  id: songInPlaylist.id,
  capo: songInPlaylist.capo,
  artist: songInPlaylist.artist,
  transpose: songInPlaylist.transpose
})
